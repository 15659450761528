<!--
 * @Author: Code-HHX
 * @Date: 2022-04-12 16:49:57
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-28 11:54:34
 * @Description: 注册账号
-->
<template>
  <div class="container">
    <div class="header">
      <img
          :style="nextStep?`visibility: visible;`:`visibility: hidden;`"
          class="header-back"
          src="@/assets/icons/icon_return.png"
          alt=""
          @click="onClickBack"
      />
      <img class="header-logo" :src="brandImage" alt=""/>
      <img
          :style="nextStep?`visibility: hidden;`:`visibility: visible;`"
          class="header-close"
          src="@/assets/icons/icon_close.png"
          alt=""
          @click="onClickClose"
      />
    </div>
    <div class="content">
      <div class="first-step" v-if="!nextStep">
        <div class="title">Create Account</div>
        <input
            class="email"
            type="text"
            v-model="accountInfo.email"
            placeholder="Email address"
            maxlength="30"
            :class="
          submitValidation &&
          (this.$utils.isNullAndEmpty(accountInfo.email) ||
            !this.$utils.isEmail(accountInfo.email))
            ? 'emptyError'
            : ''
        "
        />
        <div class="password">
          <input
              type="password"
              ref="password"
              v-model="accountInfo.password"
              placeholder="Password"
              maxlength="20"
              :class="
            submitValidation &&
            (this.$utils.isNullAndEmpty(accountInfo.password) || passwordLength)
              ? 'emptyError'
              : ''
          "
          />
          <img
              v-if="passWordVisible"
              class="fa fa-eye-slash"
              src="@/assets/icons/icon_eye.png"
              @click="onClickEye"
          />
          <img
              v-else
              class="fa-visible fa-eye-slash"
              src="@/assets/icons/icon_eye_visible.png"
              @click="onClickEye"
          />
        </div>
        <div v-show="passwordLength" class="errormsg">
          6-20 letters/numbers/characters
        </div>
        <div class="password">
          <input
              type="password"
              ref="confirmPassword"
              v-model="accountInfo.confirmPassword"
              placeholder="Confirm password"
              maxlength="20"
              :class="(submitValidation && (confirmPasswordError || confirmPassWordLength) ? 'emptyError': '')"
          />
          <img
              v-if="confirmPassWordVisible"
              class="fa fa-eye-slash"
              src="@/assets/icons/icon_eye.png"
              @click="onClickConfirmEye"
          />
          <img
              v-else
              class="fa-visible fa-eye-slash"
              src="@/assets/icons/icon_eye_visible.png"
              @click="onClickConfirmEye"
          />
        </div>
        <div v-show="(submitValidation && confirmPasswordError)" class="errormsg">
          The confirmation password does not match the password. Please
          <span @click="onClickResetIt">reset it.</span>
        </div>


        <div class="title" style="margin-top:30px;">Invitation Code (Business account only)</div>
        <input
            class="email"
            type="text"
            v-model="accountInfo.invitationCode"
            placeholder="Enter invitation code to be brand"
            maxlength="4"
            :class="(submitValidation &&confirmInvitationLength) ? 'emptyError': ''"
        />


        <div class="privacy-policy">

          <van-checkbox v-model="checkedPrivacyPolicy" shape="square" :checked-color="themeMainColor">
            I agree to the <span class="policy-item" @click="goPrivacyPolicy">Privacy Policy</span> , <span
              class="policy-item" @click="goUserCondition">Terms and Conditions</span>
          </van-checkbox>
        </div>

        <button class="button-confirm " @click="onClickNext">
          Confirm
        </button>
      </div>

      <div class="first-step" v-if="nextStep">
        <div class="title-info">Contact Info</div>
        <input
            class="info-input"
            type="text"
            v-model="accountInfo.companyName"
            placeholder="Company name"
            :class="(submitValidation && confirmCompanyNameLength)? 'emptyError' : ''"
        />
        <input
            class="info-input"
            type="text"
            v-model="accountInfo.brandName"
            placeholder="Brand name"
            :class="(submitValidation && confirmBrandNameLength)? 'emptyError' : ''"
        />
        <input
            class="info-input"
            type="text"
            v-model="accountInfo.name"
            placeholder="Name"
            :class="(submitValidation && confirmNameLength)? 'emptyError' : ''"
        />
        <input
            class="info-input"
            type="text"
            v-model="accountInfo.phoneNumber"
            placeholder="Phone number"
            :class="(submitValidation && confirmPhoneNumberLength)? 'emptyError' : ''"
        />
        <button class="button-confirm " @click="onClickConfirm">
          Confirm
        </button>
      </div>
    </div>
    <div class="footer"></div>
  </div>
</template>

<script>
import log from "@/util/log";
import { mapState } from "vuex";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

export default {
  name: "Register",
  components: { PrivacyPolicy },
  data() {
    return {
      submitValidation: false,
      passWordVisible: false, //默认不可见
      confirmPassWordVisible: false, //
      accountInfo: {
        email: "",
        password: "",
        confirmPassword: "",
        companyName: "",
        brandName: "",
        name: "",
        phoneNumber: "",
        invitationCode: ""
      },
      nextStep: false,
      checkedPrivacyPolicy: true
    };
  },
  computed: {
    ...mapState(["brandImage"]),
    ...mapState("theme", ["themeMainColor"]),
    disabledConfirm() {
      if (
          this.$utils.isNullAndEmpty(this.accountInfo.email) ||
          !this.$utils.isEmail(this.accountInfo.email) ||
          this.$utils.isNullAndEmpty(this.accountInfo.password) ||
          this.$utils.isNullAndEmpty(this.accountInfo.confirmPassword)
      ) {
        return true;
      } else {
        return false;
      }
    },
    passwordLength() {
      if (
          this.submitValidation &&
          (this.accountInfo.password.length < 6 ||
              this.accountInfo.password.length > 20)
      ) {
        return true;
      } else {
        return false;
      }
    },
    confirmPassWordLength() {
      if (
          this.submitValidation &&
          (this.accountInfo.confirmPassword.length < 6 ||
              this.accountInfo.confirmPassword.length > 20)
      ) {
        return true;
      } else {
        return false;
      }
    },
    confirmPasswordError() {
      if (
          !this.$utils.isNullAndEmpty(this.accountInfo.confirmPassword) &&
          this.accountInfo.confirmPassword != this.accountInfo.password
      ) {
        return true;
      } else {
        return false;
      }
    },
    confirmCompanyNameLength() {
      if (!this.$utils.isNullAndEmpty(this.accountInfo.companyName)) {
        return false;
      } else {
        return true;
      }
    },
    confirmBrandNameLength() {
      if (!this.$utils.isNullAndEmpty(this.accountInfo.brandName)) {
        return false;
      } else {
        return true;
      }
    },
    confirmNameLength() {
      if (!this.$utils.isNullAndEmpty(this.accountInfo.name)) {
        return false;
      } else {
        return true;
      }
    },
    confirmPhoneNumberLength() {
      if (!this.$utils.isNullAndEmpty(this.accountInfo.phoneNumber)) {
        return false;
      } else {
        return true;
      }
    },
    confirmInvitationLength() {
      if (this.submitValidation && this.accountInfo.invitationCode.length > 0 && this.accountInfo.invitationCode.length !== 4) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    goPrivacyPolicy() {
      this.$router.push({
        name: "PrivacyPolicy",
        params: {
          title: "Privacy Policy",
          type: 1
        },
        query: {
          hash:this.$store.state.hash
        }
      });
    },
    goUserCondition() {
      this.$router.push({
        name: "PrivacyPolicy",
        params: {
          title: "Terms and Conditions",
          type: 2
        },

        query: {
          hash:this.$store.state.hash
        }
      });
    },
    onClickEye() {
      this.passWordVisible = !this.passWordVisible;
      if (this.$refs.password.type == "password") {
        this.$refs.password.type = "text";
      } else {
        this.$refs.password.type = "password";
      }
    },
    onClickConfirmEye() {
      this.confirmPassWordVisible = !this.confirmPassWordVisible;
      if (this.$refs.confirmPassword.type == "password") {
        this.$refs.confirmPassword.type = "text";
      } else {
        this.$refs.confirmPassword.type = "password";
      }
    },
    onClickResetIt() {
      this.accountInfo.confirmPassword = "";
      this.$refs.confirmPassword.focus();
    },
    async onClickNext() {
      if (!this.checkedPrivacyPolicy) {
        this.$toast({
          type: "fail",
          message: `Please view <Privacy Policy> and checkbox it `
        });
        return;
      }
      this.submitValidation = true;
      if (this.disabledConfirm || this.confirmPasswordError || this.passwordLength || this.confirmPassWordLength) {
        return;
      }


      try {
        if (this.accountInfo.invitationCode) {
          if (this.accountInfo.invitationCode.length > 0 && this.accountInfo.invitationCode.length !== 4) {
            this.$toast({
              type: "fail",
              message: "Please input correctly invitation code"
            });
            return;
          }

          const response = await this.$api.User.verificationInvitationCode({
            email: this.accountInfo.email,
            invitationCode: this.accountInfo.invitationCode
          });
          if (response.code === 200) {
            this.nextStep = true;
          } else {
            this.$toast({
              type: "fail",
              message: response.message
            });
          }

        } else {
          await this.onSubmit();
        }
      } catch (e) {
        this.$toast({
          type: "fail",
          message: e
        });
      } finally {
      }


      this.submitValidation = false;
    },
    onSubmit() {
      this.$api.User.registerBrandUser(this.accountInfo)
          .then(res => {
            log(res);
            if (res.code == 200) {
              this.$dialog
                  .alert({
                    message: res.message
                  })
                  .then(() => {
                    this.$router.go(-1);
                  });
            } else {
              this.$dialog
                  .alert({
                    message: res.message
                  })
                  .then(() => {
                  });
            }
          })
          .catch(error => {
            this.$dialog
                .alert({
                  message: error
                })
                .then(() => {
                });
          });
    },
    onClickConfirm() {
      this.submitValidation = true;

      if (
          this.disabledConfirm ||
          this.confirmCompanyNameLength ||
          this.confirmBrandNameLength ||
          this.confirmNameLength ||
          this.confirmPhoneNumberLength
      ) {
        return;
      }

      this.$api.User.registerBrandUser(this.accountInfo)
          .then(res => {
            log(res);
            if (res.code == 200) {
              this.$dialog
                  .alert({
                    message: res.message
                  })
                  .then(() => {
                    this.$router.go(-1);
                  });
            } else {
              this.$dialog
                  .alert({
                    message: res.message
                  })
                  .then(() => {
                  });
            }
          })
          .catch(error => {
            this.$dialog
                .alert({
                  message: error
                })
                .then(() => {
                });
          });
    },
    onClickClose() {
      this.$router.go(-1);
    },
    onClickBack() {
      this.nextStep = false;
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  .header {
    padding-top: 40px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;

    .header-logo {
      margin: 0 auto;
    }

    .header-back {
      width: 30px;
      height: 30px;
      margin-top: 5px;
      padding-left: 25px;
    }

    .header-close {
      width: 30px;
      height: 30px;
      margin-top: 5px;
      padding-right: 25px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 0 25px;

    .first-step {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 18px;
      font-weight: normal;
      color: #231f20;
      padding-left: 4px;
      margin-bottom: 14px;
    }

    .email {
      border: 1px solid transparent;
    }

    .password {
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      input {
        border: 1px solid transparent;
      }

      .password-input {
        width: 90% !important;
      }

      .fa {
        position: absolute;
        right: 40px;
        width: 32px;
        height: 20px;
      }

      .fa-visible {
        position: absolute;
        right: 40px;
        width: 32px;
        height: 29px;
      }
    }

    .errormsg {
      margin-top: 9px;
      font-size: 16px;
      font-weight: normal;
      color: #d66384;

      span {
        text-decoration: underline;
        cursor: pointer;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
        -webkit-user-select: none;
        -moz-user-focus: none;
        -moz-user-select: none;
      }
    }

    .title-info {
      font-size: 18px;
      font-weight: normal;
      color: #231f20;
      padding-left: 4px;
      margin-bottom: 15px;

      span {
        font-size: 12px;
      }
    }

    .info-input {
      margin-bottom: 10px;
    }

    .privacy-policy {
      margin-top: 20px;
      font-size: 14px;

      .policy-item {
        color: var(--themeMainColor);
      }
    }

    .button-confirm {
      margin-top: 40px;
      background: var(--themeMainColor);
    }

    .emptyError {
      background: #f4e6e9 !important;
      border: 1px solid #e08cae !important;
    }
  }
}

::v-deep .van-checkbox {
  align-items: flex-start;
}
</style>
